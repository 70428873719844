import { useViewerQuery } from 'generated/gorilla'

const sre = [
  'venkata@wandb.com',
  'elaina@wandb.com',
  'ethan.ho@wandb.com',
  'justin@wandb.com',
  'vanpelt@wandb.com',
  'flamarion.jorge@wandb.com',
  'andrew@wandb.com',
  'zachary.blasczyk@wandb.com',
  'aditya.choudhari@wandb.com',
  'nathan.kuneman@wandb.com',
  'marcsteffen.kaesz@wandb.com',
  'jonathan.stanley@wandb.com',
  'abraham.leal@wandb.com',
  'vijay.panneerselvam@wandb.com',
  'jonathan.meeks@wandb.com',
  'daniel.barnes@wandb.com',
  'man.waiip@wandb.com',
  'uma.krishnaswamy@wandb.com'
]

export const useViewer = () => {
  const { data, loading } = useViewerQuery()
  const viewer = data?.viewer
  const isViewerAdmin = viewer?.admin != null && viewer?.admin
  const isSre = sre.includes(viewer?.email?.toLowerCase() ?? '')
  return { viewer, isViewerAdmin, isSre, loading }
}
